.nice-themeselect-md-cutom-menu {
  margin-left: 8px;
  border: 1px dashed #1890ff;
}
.nice-themeselect-md-menu {
  margin-left: 8px;
}
.nice-themeselect-code-menu {
  margin-left: 8px;
  margin-right: 8px;
}
.nice-themeselect-theme-item {
  display: flex;
  justify-content: space-between;
  padding: 5px 12px 5px 6px;
}
.nice-themeselect-theme-item-author {
  color: gray;
}
.nice-themeselect-theme-item-name {
  margin-right: 10px;
}
.nice-themeselect-theme-item-new {
  border-radius: 3px;
  font-size: 12px;
  color: #1890ff;
  background: rgb(230, 247, 255);
  padding: 0px 5px;
  margin-left: -5px;
  line-height: 18px;
}
.nice-themeselect-theme-item-flag {
  display: inline-block;
  width: 16px;
}
.nice-themeselect-icon {
  width: 18px;
  height: 18px;
}
.nice-themeselect-menu-item {
  clear: both;
  margin: 0;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  cursor: pointer;
}
