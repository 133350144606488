/*

    Name:       Base16 Default Light
    Author:     Chris Kempson (http://chriskempson.com)

    CodeMirror template by Jan T. Sott (https://github.com/idleberg/base16-codemirror)
    Original Base16 color scheme by Chris Kempson (https://github.com/chriskempson/base16)

*/

.cm-s-style-mirror.CodeMirror {
  background: #f5f5f5;
  color: #202020;
  font-size: 14px;
  padding: 20px;
  line-height: 25px;
}
.cm-s-style-mirror div.CodeMirror-selected {
  background: #e0e0e0;
}
.cm-s-style-mirror .CodeMirror-line::selection,
.cm-s-style-mirror .CodeMirror-line > span::selection,
.cm-s-style-mirror .CodeMirror-line > span > span::selection {
  background: #e0e0e0;
}
.cm-s-style-mirror .CodeMirror-line::-moz-selection,
.cm-s-style-mirror .CodeMirror-line > span::-moz-selection,
.cm-s-style-mirror .CodeMirror-line > span > span::-moz-selection {
  background: #e0e0e0;
}
.cm-s-style-mirror .CodeMirror-gutters {
  background: #f5f5f5;
  border-right: 0px;
}
.cm-s-style-mirror .CodeMirror-guttermarker {
  color: #ac4142;
}
.cm-s-style-mirror .CodeMirror-guttermarker-subtle {
  color: #b0b0b0;
}
.cm-s-style-mirror .CodeMirror-linenumber {
  color: #b0b0b0;
}
.cm-s-style-mirror .CodeMirror-cursor {
  border-left: 1px solid #505050;
}

.cm-s-style-mirror span.cm-comment {
  color: #ac002b;
}
.cm-s-style-mirror span.cm-atom {
  color: #aa759f;
}
.cm-s-style-mirror span.cm-number {
  color: #aa759f;
}

.cm-s-style-mirror span.cm-property,
.cm-s-style-mirror span.cm-attribute {
  color: #90a959;
}
.cm-s-style-mirror span.cm-keyword {
  color: #023a52;
}
.cm-s-style-mirror span.cm-string {
  color: #e46918;
}

.cm-s-style-mirror span.cm-variable {
  color: #90a959;
}
.cm-s-style-mirror span.cm-variable-2 {
  color: #00695f;
}
.cm-s-style-mirror span.cm-variable-3 {
  color: #2e6e8a;
}
.cm-s-style-mirror span.cm-def {
  color: #d28445;
}
.cm-s-style-mirror span.cm-bracket {
  color: #202020;
}
.cm-s-style-mirror span.cm-tag {
  color: #ac4142;
}
.cm-s-style-mirror span.cm-link {
  color: #b26a00;
}
.cm-s-style-mirror span.cm-error {
  /* background: #ac4142;
  color: #f5f5f5; */
  text-decoration: underline;
  text-decoration-style: wavy;
  text-decoration-color: #df8d8e;
}
.cm-s-style-mirror .CodeMirror-activeline-background {
  background: #dddcdc;
}
.cm-s-style-mirror .CodeMirror-matchingbracket {
  color: rgb(32, 32, 32) !important;
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.CodeMirror-hints {
  background-color: rgb(245, 245, 245);
  font-size: 14px;
  font-family: 'source-code-pro', Menlo, 'Courier New', Consolas, monospace;
}

.CodeMirror-hints li {
  line-height: 20px;
}

li.CodeMirror-hint-active  {
  color: black;
  background-color: rgb(202,222,185);
}

.CodeMirror-hints li:hover {
  background-color: rgb(224, 224, 224);
}
