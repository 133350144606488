.nice-btn-juejin {
  padding: 0;
  margin-bottom: 10px;
}

.nice-btn-juejin-icon {
  padding: 5px 5px 5px 2px;
  width: 30px;
  height: 28px;
}
