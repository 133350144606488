.nice-btn-previewtype {
  padding: 0;
  margin-bottom: 10px;
}

.nice-btn-previewtype-icon {
  padding: 7px;
  width: 30px;
  height: 30px;
}
